<template>
	<div class="my-banner" :style="{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${banner.image})` }" v-if="banner">
		<h4 class="my-banner__subtitle" v-html="banner.subtitle"></h4>
		<h2 class="my-banner__title" v-html="banner.title"></h2>
		<p class="my-banner__description" v-html="banner.description"></p>
		<a :href="banner.url" target="_blank" v-html="banner.button || 'Get Started'" class="my-banner__button button button--unelevated button--primary button--reverse"></a>
	</div>
</template>

<script>
export default {
	name: 'MyBanner',
	mounted() {
		this.$store.dispatch('banner/list', {p: 1, limit: 1})
	},
	computed: {
		banners() {
			return this.$store.getters['banner/banners']
		},
		banner() {
			return this.banners.length ? this.banners[0] : null
		},
	}
}
</script>

<style scoped lang="scss">
@use '@material/elevation';

.my-banner {
	@include modules.gutter('margin-bottom');
	@include modules.gutter('padding');
	@include elevation.elevation(20, rgba(0, 0, 0, 0.15), -0.1);

	background-color: rgba(modules.color_('primary'), 0.5);
	background-size: cover;
	background-position: center center;
	border-radius: modules.$border-radius-lg;

	&__title {
		@include modules.fontSize(60px);
		@include modules.gutter('margin-bottom');
		@include modules.gutter('margin-top');
		color: modules.color_('primary','text');
		font-weight: 700;
		line-height: 1.05;
		// margin: 0;
	}

	&__subtitle {
		@include modules.fontSize(14px);
		@include modules.gutter('margin-bottom');
		// @include modules.gutter('margin-top');
		color: modules.color_('primary','text');
		font-weight: 500;
		text-transform: uppercase;
		// margin: 0;
	}

	&__description {
		@include modules.fontSize(20px);
		@include modules.gutter('margin-bottom');
		@include modules.gutter('margin-top');
		color: modules.color_('primary','text');
	}

	&__button {
		@include modules.gutter('margin-bottom');
	}
}
</style>
